import { render, staticRenderFns } from "./DetailFiles.vue?vue&type=template&id=2036808c&scoped=true&"
import script from "./DetailFiles.vue?vue&type=script&lang=js&"
export * from "./DetailFiles.vue?vue&type=script&lang=js&"
import style0 from "./DetailFiles.vue?vue&type=style&index=0&id=2036808c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2036808c",
  null
  
)

export default component.exports